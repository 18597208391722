import { BaseIconPathStyle, BaseSvgStyle } from '../IconBaseStyle';
import { ComponentProps } from '../SVGBase';

function Phone({ size, color }: ComponentProps) {
  return (
    <BaseSvgStyle
      size={size}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <BaseIconPathStyle
        d="M15.0402 22.39L15.0522 22.397C16.1143 23.0732 17.3753 23.3669 18.6269 23.2297C19.8785 23.0924 21.0459 22.5323 21.9362 21.642L22.7102 20.868C23.0559 20.5219 23.2501 20.0527 23.2501 19.5635C23.2501 19.0743 23.0559 18.6051 22.7102 18.259L19.4492 15C19.1031 14.6543 18.6339 14.4601 18.1447 14.4601C17.6555 14.4601 17.1863 14.6543 16.8402 15V15C16.669 15.1714 16.4658 15.3073 16.242 15.4001C16.0183 15.4929 15.7784 15.5406 15.5362 15.5406C15.294 15.5406 15.0541 15.4929 14.8304 15.4001C14.6066 15.3073 14.4034 15.1714 14.2322 15L9.01421 9.78099C8.66851 9.43487 8.47434 8.96568 8.47434 8.47649C8.47434 7.98731 8.66851 7.51812 9.01421 7.17199V7.17199C9.18558 7.00083 9.32154 6.79755 9.4143 6.5738C9.50706 6.35005 9.55481 6.11021 9.55481 5.86799C9.55481 5.62578 9.50706 5.38594 9.4143 5.16218C9.32154 4.93843 9.18558 4.73516 9.01421 4.56399L5.7542 1.29999C5.40808 0.954303 4.93889 0.760132 4.4497 0.760132C3.96052 0.760132 3.49133 0.954303 3.1452 1.29999L2.3712 2.07399C1.48103 2.96437 0.921017 4.13164 0.783575 5.38315C0.646134 6.63467 0.93948 7.89565 1.6152 8.95799L1.6232 8.96999C5.19662 14.2598 9.75118 18.8154 15.0402 22.39V22.39Z"
        strokeRound
        stroke={color}
      />
    </BaseSvgStyle>
  );
}

export default Phone;
